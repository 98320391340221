<template>
	<div class="home-background">
		<div class="background" :class="day_moment">
			<img class="img sky" :src="sky_url" />
			<div v-if="day_moment === 'night'" class="stars">
				<div v-for="i in 150" class="star"></div>
			</div>
			<div class="clouds">
				<div v-for="i in 6" class="cloud" :class="'cloud-' + i"></div>
			</div>
			<img class="img clinic" id="bg-clinic" :src="clinic_url" @load="$emit('loaded')" />
		</div>
	</div>
</template>

<script>

export default {
	name: 'home-background',
	data() {
		return {

		}
	},
	props: {
		day_moment: { default: 'day' }
	},
	computed: {
		sky_url() {
			return this.$medias.find({ tags: [ 'background', this.day_moment, 'sky' ] })
		},
		clinic_url() {
			return this.$medias.find({ tags: [ 'background', this.day_moment, 'hospital' ] })
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

#app.mobile
	.home-background .background .clouds
		display none

.home-background
	absolute 0
	background-color #fff
	.background
		position absolute
		top -3px
		left 0
		bottom 0
		right 0
		.img
			position absolute
			left 50%
			bottom 0
			height 100%
			transform translateX(-50%)
			&.clinic
				opacity 1
		.clouds
			absolute 0
			.cloud
				absolute 0
				transform translate3D(25%, 0, 0)
				background center center no-repeat
				opacity 0
				duration = 20s

				for i in 1..6
					&.cloud-{i}
						background-position ((i - 1) * 20%) random(1%, 20%)
						animation cloud (duration * random(1, 2)) linear infinite
						animation-delay round(random(0s, 5s) * random(0, 1))
						background-size round(random(20%, 30%)) auto

				&.cloud-1
					background-image url(../assets/img/home/cloud-1.svg)
				&.cloud-2
					background-image url(../assets/img/home/cloud-2.svg)
				&.cloud-3
					background-image url(../assets/img/home/cloud-3.svg)
				&.cloud-4
					background-image url(../assets/img/home/cloud-1.svg)
				&.cloud-5
					background-image url(../assets/img/home/cloud-2.svg)
				&.cloud-6
					background-image url(../assets/img/home/cloud-2.svg)
		.stars
			absolute 0
			.star
				position absolute
				background-color #d4a0f2
				border-radius 50%
				opacity 0.5
				transform scale(0.8)
				for i in 1..150
					&:nth-child({i})
						size = random(1px, 8px)
						left random(2%, 98%)
						top random(2%, 50%)
						height size
						width size
						animation shine random(4s, 6s) linear infinite
						animation-delay random(0s, 5s)


@keyframes cloud
	0%
		opacity 0
		transform translate3D(25%, 0, 0)
	5%
		opacity 0.8
	95%
		opacity 0.8
	100%
		opacity 0
		transform translate3D(-25%, 0, 0)


@keyframes shine
	0%, 50%, 100%
		opacity 0.5
		transform scale(0.7)
	25%
		opacity 1
		transform scale(1)
	75%
		opacity 1
		transform scale(1)

</style>
